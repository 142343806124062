import { isNil } from 'lodash'
import qs from 'qs'

import { utils } from '@fullfabric/public-api'

export default async function getStaff({
  q,
  offset,
  limit,
  excludeSupport,
  teams,
  roles,
  'sort[field]': sortField,
  'sort[direction]': sortDirection,
  ...rest
} = {}) {
  // Filter by profiles with staff roles only:
  const segmentRules = [
    {
      type: 'Segments::Rules::Context::PlaysRole',
      value: 'staff'
    }
  ]

  // Non-support staff shouldn't have access to support staff:
  if (excludeSupport) {
    segmentRules.push({
      type: 'Segments::Rules::Context::NotRole',
      values: 'staff::support'
    })
  }

  // Filter by selected teams:
  if (teams?.length > 0) {
    segmentRules.push({
      type: 'Segments::Rules::Profiles::InTeams',
      values_as_hash: { ids: teams }
    })
  }

  // Filter by selected roles:
  if (roles?.length > 0) {
    segmentRules.push({
      type: 'Segments::Rules::Context::PlaysRole',
      values: roles
    })
  }

  const query = {
    rules: segmentRules.reduce(
      (acc, rule, index) => Object.assign(acc, { [index]: rule }),
      {}
    )
  }

  // Paginate:
  if (!isNil(offset)) query.offset = offset
  if (!isNil(limit)) query.limit = limit

  // Search:
  if (!isNil(q)) query.q = q

  // Sort:
  if (!isNil(sortField) && !isNil(sortDirection)) {
    query.sort = { field: sortField, direction: sortDirection }
  }

  // Fetch and respond:
  const queryString = qs.stringify(query, { arrayFormat: 'brackets' })
  const response = await fetch(`/api/profiles?${queryString}`, {
    credentials: 'same-origin'
  })

  const data = await utils.checkResponse(response)
  const headers = response.headers.get('x-fullfabric-json')

  return { data, ...JSON.parse(headers) }
}
