import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { Trans, useTranslation } from 'react-i18next'
import { useMutation, useQueryClient } from 'react-query'

import {
  Button,
  Field,
  Modal,
  ModalButtons,
  ModalTitle,
  Text
} from '@fullfabric/alma-mater'

import createTeam from 'shared/api/teams/createTeam'

export default function CreateTeamButton() {
  const { t } = useTranslation()
  const [isCreationModalOpen, setCreationModalOpen] = useState(false)

  const {
    register,
    handleSubmit,
    formState,
    reset: resetForm,
    setFocus
  } = useForm()

  const { ref: nameInputRef, ...nameInputProps } = register('name', {
    required: t("Can't be blank")
  })

  useEffect(() => {
    isCreationModalOpen && setFocus('name')
  }, [isCreationModalOpen, setFocus])

  const closeModal = () => {
    setCreationModalOpen(false)
    resetForm()
  }

  const createTeamMutation = useCreateTeamMutation(closeModal)
  const onSubmit = (formValues) => createTeamMutation.mutate({ formValues })

  return (
    <>
      <Button primary size='small' onClick={() => setCreationModalOpen(true)}>
        <Trans>Create a new team</Trans>
      </Button>

      {isCreationModalOpen && (
        <Modal
          id='create-team-modal'
          role='dialog'
          aria-labelledby='create-team-modal-title'
          isOpen={isCreationModalOpen}
          size='small'
          header={
            <ModalTitle
              title={
                <span id='create-team-modal-title'>
                  <Trans>Create a new team</Trans>
                </span>
              }
              onClose={closeModal}
            />
          }
          footer={
            <ModalButtons>
              <Button
                onClick={closeModal}
                disabled={createTeamMutation.isLoading}
              >
                <Trans>Cancel</Trans>
              </Button>

              <Button
                primary
                type='submit'
                form='create-team-modal-form'
                onClick={handleSubmit(onSubmit)}
                loading={createTeamMutation.isLoading}
              >
                <Trans>Create team</Trans>
              </Button>
            </ModalButtons>
          }
        >
          <form id='create-team-modal-form'>
            <Field
              {...nameInputProps}
              type='text'
              label={<Trans>Name</Trans>}
              error={formState.errors.name?.message}
              inputRef={nameInputRef}
            />
          </form>

          {createTeamMutation.isError && (
            <Text fontColor='text-alert' type='f5' marginTop='default'>
              <Trans>
                There was an error, please refresh the page and try again. If
                the issue persists, please contact us.
              </Trans>
            </Text>
          )}
        </Modal>
      )}
    </>
  )
}

function useCreateTeamMutation(onSuccessCb) {
  const queryClient = useQueryClient()

  return useMutation(({ formValues }) => createTeam(formValues), {
    onSuccess: (team) => {
      queryClient.setQueryData('teams', (teams) => [...teams, team])
      onSuccessCb(team)
    }
  })
}
