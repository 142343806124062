import React from 'react'
import { useMutation } from 'react-query'

import { DropdownInput, Table } from '@fullfabric/alma-mater'

import setRoleState from 'shared/api/setRoleState'
import getStaff from 'shared/api/staff/getStaff'
import cellStyles from 'shared/components/cells/Cells.module.scss'
import { usePagedQueriesUpdater } from 'shared/hooks/usePagedQueriesUpdater'

export default function RoleCell({
  data,
  roles,
  isLoading,
  value: profileRoles,
  row
}) {
  const profileId = data[row.index].id
  const staffRole = profileRoles.find(({ state }) => state === 'staff')

  const { mutate: roleMutation, isLoading: isRoleLoading } = useRoleMutation(
    profileId,
    staffRole?._id
  )

  if (!staffRole) {
    // Not really supposed to happen, but let's not break the whole page if it
    // does.

    return (
      <Table.Data tag='div' className={cellStyles.customCell}>
        -
      </Table.Data>
    )
  }

  return (
    <Table.Data
      tag='div'
      className={cellStyles.customCell}
      title={staffRole.state_and_substate}
    >
      <DropdownInput
        inModal
        small
        disabled={isRoleLoading}
        id={`${profileId}-role-dropdown`}
        isLoading={isLoading}
        value={staffRole.state_and_substate}
        options={prepareRoles(roles || [])}
        onChange={roleMutation}
      />
    </Table.Data>
  )
}

function prepareRoles(roles) {
  return roles.map(({ name }) => ({ value: name, label: name }))
}

function useRoleMutation(profileId, roleId) {
  const updatePages = usePagedQueriesUpdater([
    'tableDataFetch',
    undefined,
    getStaff
  ])

  return useMutation(
    (newRole) => {
      const [state, substate] = newRole.split('::')
      setRoleState(profileId, roleId, { state, substate })
    },
    {
      onSuccess: (_data, newRole) => {
        const [state, substate] = newRole.split('::')

        updatePages((page) => {
          page.data.forEach((profile) => {
            if (profile.id === profileId) {
              profile.roles.forEach((role) => {
                if (role._id === roleId) {
                  role.state = state
                  role.substate = substate
                  role.state_and_substate = newRole
                }
              })
            }
          })
        })
      }
    }
  )
}
