import React from 'react'
import { Trans } from 'react-i18next'
import { useQuery } from 'react-query'

import {
  Badge,
  EditIcon,
  Link,
  Loading,
  PlainButton,
  Table
} from '@fullfabric/alma-mater'

import getTeams from 'shared/api/teams/getTeams'

import styles from './TeamsTable.module.scss'

export default function TeamsTable() {
  const { data: teams, isLoading } = useQuery('teams', getTeams)

  return (
    <Table className={styles.teamsTable}>
      <Table.Header>
        <Table.Row>
          <Table.HeaderItem>
            <Trans>Team name</Trans>
          </Table.HeaderItem>

          <Table.HeaderItem>
            <Trans>People</Trans>
          </Table.HeaderItem>

          <Table.HeaderItem>
            <Trans>Access Scope</Trans>
          </Table.HeaderItem>

          <Table.HeaderItem>
            <Trans></Trans>
          </Table.HeaderItem>
        </Table.Row>
      </Table.Header>

      <Table.Body>
        {isLoading ? (
          <Table.Row>
            <Table.Data colSpan='100' align='center'>
              <Loading />
            </Table.Data>
          </Table.Row>
        ) : (
          teams.map((team) => (
            <Table.Row key={team.id}>
              <Table.Data main>{team.name}</Table.Data>

              <Table.Data>{team.profile_count}</Table.Data>

              <Table.Data>
                {team.authorization_agent_access_segment_id ? (
                  <Badge type='purple'>Yes</Badge>
                ) : (
                  <Badge type='grey'>No</Badge>
                )}
              </Table.Data>

              <Table.Data className={styles.actionCell}>
                <Link
                  withoutStyles
                  href={`/staff#teams/${team.id}/edit`}
                  target='_parent'
                >
                  <PlainButton primary>
                    Edit
                    <PlainButton.RightIcon>
                      <EditIcon />
                    </PlainButton.RightIcon>
                  </PlainButton>
                </Link>
              </Table.Data>
            </Table.Row>
          ))
        )}
      </Table.Body>
    </Table>
  )
}
