import React, { useEffect, useMemo, useRef } from 'react'
import { Trans } from 'react-i18next'
import { useQuery } from 'react-query'

import { sortBy } from 'lodash'

import { Badge } from '@fullfabric/alma-mater'
import {
  AuthorizationOfficer,
  useCurrentUser,
  UserRoleUtils,
  useSettings
} from '@fullfabric/authorization-officer'

import getRoles from 'shared/api/authorization/getRoles'
import getStaff from 'shared/api/staff/getStaff'
import getTeams from 'shared/api/teams/getTeams'
import AutoloadDataTable from 'shared/components/AutoloadDataTable'
import cellStyles from 'shared/components/cells/Cells.module.scss'

import DateTimeCell from './UsersTable/DateTimeCell'
import RoleCell from './UsersTable/RoleCell'
import StatusCell from './UsersTable/StatusCell'
import TeamsCell from './UsersTable/TeamsCell'

import styles from './UsersTable.module.scss'

const FILTER_STORAGE_KEY = 'staff-users-table:filter:v1'

const TEAMS_FILTER_SPEC = {
  id: 'teams',
  name: 'Teams',
  labelValueSeparator: ':',
  type: 'multi'
}

const ROLES_FILTER_SPEC = {
  id: 'roles',
  name: 'Roles',
  labelValueSeparator: ':',
  type: 'multi'
}

export default function UsersTable() {
  const user = useCurrentUser()
  const { columns, filters } = useUserColumnsFilters(
    UserRoleUtils.isSupport(user)
  )

  return (
    <AutoloadDataTable
      fixedLayout
      manipulationSectionClassName={styles.usersTable}
      fetchFn={getStaff}
      fetchParams={
        UserRoleUtils.isSupport(user) ? {} : { excludeSupport: true }
      }
      reactQueryProps={{ staleTime: 0 }}
      emptyMessage={<Trans>No data</Trans>}
      columns={columns}
      filterable
      filterStorageKey={FILTER_STORAGE_KEY}
      filters={filters}
      loadMoreProps={{
        sizeKey: 'limit',
        offsetKey: 'offset'
      }}
      searchable
      searchParamKey='q'
      sortable
      sortParams={{
        sortKey: 'sort[field]',
        directionKey: 'sort[direction]',
        ascValue: 1,
        descValue: -1
      }}
      initialSort={{
        'sort[field]': '_search_name',
        'sort[direction]': 1
      }}
    />
  )
}

function useUserColumnsFilters(userIsSupport) {
  const settings = useSettings()
  const teamsPromiseRef = useRef(Promise.withResolvers())
  const rolesPromiseRef = useRef(Promise.withResolvers())

  const teamsQuery = useQuery('teams', getTeams)
  const rolesQuery = useQuery('getRoles', getRoles)

  const roles = useMemo(
    () => rolesQuery.data && visibleRoles(rolesQuery.data, userIsSupport),
    [rolesQuery.data, userIsSupport]
  )

  const StatelessTeamsCell = useMemo(() => {
    return (props) => (
      <TeamsCell
        {...props}
        teams={teamsQuery.data}
        isLoading={teamsQuery.isLoading}
      />
    )
  }, [teamsQuery.data, teamsQuery.isLoading])

  const StatelessRoleCell = useMemo(() => {
    return (props) => (
      <RoleCell {...props} roles={roles} isLoading={rolesQuery.isLoading} />
    )
  }, [roles, rolesQuery.isLoading])

  useEffect(() => {
    if (teamsQuery.isLoading) return
    if (teamsQuery.isError) teamsPromiseRef.current.reject(teamsQuery.error)
    if (teamsQuery.data)
      teamsPromiseRef.current.resolve(teamOptions(teamsQuery.data))
  }, [
    teamsQuery.isLoading,
    teamsQuery.isError,
    teamsQuery.data,
    teamsQuery.error
  ])

  useEffect(() => {
    if (rolesQuery.isLoading) return
    if (rolesQuery.isError) rolesPromiseRef.current.reject(rolesQuery.error)
    if (roles) rolesPromiseRef.current.resolve(rolesOptions(roles))
  }, [rolesQuery.isLoading, rolesQuery.isError, roles, rolesQuery.error])

  const columns = [
    {
      Header: <Trans>Name</Trans>,
      id: '_search_name',
      accessor: 'full_name',
      linkTo: ({ row, data, _value }) => ({
        href: `/profiles/${data[row.index].id}`,
        target: '_parent'
      })
    },
    {
      Header: <Trans>Email</Trans>,
      accessor: 'email'
    },
    {
      Header: <Trans>Status</Trans>,
      id: 'status',
      accessor: 'roles',
      Cell: StatusCell,
      disableSortBy: true
    }
  ]

  if (
    AuthorizationOfficer.moduleHasFeature('core.profiles', 'teams', settings)
  ) {
    columns.push({
      Header: <Trans>Teams</Trans>,
      id: 'teams_ids',
      accessor: 'teams_ids',
      Cell: StatelessTeamsCell,
      disableSortBy: true
    })
  }

  columns.push({
    Header: <Trans>Role</Trans>,
    accessor: 'roles',
    Cell: StatelessRoleCell,
    disableSortBy: true
  })

  if (
    AuthorizationOfficer.moduleHasFeature('core.emails', 'user_smtp', settings)
  ) {
    columns.push({
      Header: <Trans>SMTP</Trans>,
      accessor: 'smtp_settings',
      Cell: ({ value }) =>
        value?.active ? (
          <Badge className={cellStyles.tableBadge} type='blue'>
            Active
          </Badge>
        ) : (
          <Badge className={cellStyles.tableBadge}>Inactive</Badge>
        ),
      disableSortBy: true,
      width: 80
    })
  }

  if (
    AuthorizationOfficer.moduleHasFeature('core.emails', 'user_imap', settings)
  ) {
    columns.push({
      Header: <Trans>IMAP</Trans>,
      accessor: 'imap_settings',
      Cell: ({ value }) =>
        value?.active ? (
          <Badge className={cellStyles.tableBadge} type='blue'>
            Active
          </Badge>
        ) : (
          <Badge className={cellStyles.tableBadge}>Inactive</Badge>
        ),
      disableSortBy: true,
      width: 80
    })
  }

  columns.push({
    Header: <Trans>Last engaged</Trans>,
    accessor: 'last_seen',
    Cell: DateTimeCell
  })

  const filters = [
    {
      ...TEAMS_FILTER_SPEC,
      loadOptions: () => teamsPromiseRef.current.promise
    },
    {
      ...ROLES_FILTER_SPEC,
      loadOptions: () => rolesPromiseRef.current.promise
    }
  ]

  return { columns, filters }
}

function teamOptions(teams) {
  return teams.map(({ id, name }) => ({ value: id, label: name }))
}

function rolesOptions(roles) {
  return roles.map(({ name }) => ({ value: name, label: name }))
}

function visibleRoles(roles, isSupport) {
  const visibleRoles = roles.filter(
    ({ name }) =>
      /^staff::/.test(name) && (isSupport || !/::support$/.test(name))
  )

  return sortBy(visibleRoles, 'name')
}
