import React from 'react'

import PropTypes from 'prop-types'

import { Table } from '@fullfabric/alma-mater'

import LoadMoreRow from '../LoadMoreRow'
import PlaceholderTable from '../PlaceholderTable'
import TableManipulationSection from '../TableManipulationSection'
import CustomExpandedTableRow from './components/CustomExpandedTableRow'
import EmptyDataRow from './components/EmptyDataRow'
import ErrorRow from './components/ErrorRow'
import TableHeader from './components/TableHeader'
import TableRow from './components/TableRow'
import useDataTable from './hooks/useDataTable'
import getTableState from './utils/getTableState'

import classNames from 'classnames'
import styles from './styles.module.scss'

/**
 * Simplest data table component powered by react-table component
 */
const DataTable = ({
  columns,
  data,
  dataSchemas,
  className,
  containerClassName,
  emptyMessage,
  loading,
  error,
  selectableRows,
  onSelectionChanged,
  expandableRows,
  ExpandedRowComponent,
  onChangeSort,
  tableProps = {},
  onLoadMoreClick,
  loadMoreProps,
  onSearch,
  initialSearch,
  searchPlaceholder,
  filters,
  onFilterChange,
  selectedFilter,
  filterProps = {},
  selectableColumns,
  onHiddenColumnsChanged,
  initialHiddenColumns,
  manipulationSectionClassName,
  fixedLayout
}) => {
  const { reactTableProps, memoizedData } = useDataTable({
    onChangeSort,
    initialHiddenColumns,
    tableProps,
    data,
    dataSchemas,
    columns,
    selectableRows,
    expandableRows: !!ExpandedRowComponent || expandableRows,
    fixedLayout,
    onHiddenColumnsChanged,
    onSelectionChanged,
    loading,
    error
  })

  const {
    allColumns,
    getTableProps,
    headerGroups,
    setSortBy,
    getTableBodyProps,
    visibleColumns,
    rows,
    prepareRow
  } = reactTableProps

  const state = getTableState(loading, error, rows.length)

  return (
    <>
      <TableManipulationSection
        className={manipulationSectionClassName}
        onSearch={onSearch}
        initialSearch={initialSearch}
        searchPlaceholder={searchPlaceholder}
        onFilterChange={onFilterChange}
        selectedFilter={selectedFilter}
        filters={filters}
        filterProps={filterProps}
        selectableColumns={selectableColumns}
        columns={allColumns}
      />

      <div className={classNames(styles.container, containerClassName)}>
        <Table className={className} {...getTableProps()}>
          <TableHeader
            headerGroups={headerGroups}
            onChangeSort={onChangeSort}
            setSortBy={setSortBy}
            isDisplayingFullTableMessage={['error', 'empty'].includes(state)}
          />

          <Table.Body {...getTableBodyProps()}>
            {state === 'loading' && (
              <PlaceholderTable rows={loading} columns={visibleColumns} />
            )}
            {state === 'error' && <ErrorRow error={error} />}
            {state === 'empty' && <EmptyDataRow emptyMessage={emptyMessage} />}

            {state === 'ready' &&
              rows.map((row) => {
                prepareRow(row)
                const { key, ...rowProps } = row.getRowProps()

                return (
                  <React.Fragment key={key}>
                    <TableRow row={row} data={memoizedData} {...rowProps} />
                    <CustomExpandedTableRow
                      row={row}
                      data={memoizedData}
                      ExpandedRowComponent={ExpandedRowComponent}
                      rowProps={rowProps}
                      tableProps={reactTableProps}
                    />
                  </React.Fragment>
                )
              })}
          </Table.Body>
        </Table>

        {onLoadMoreClick && (
          <LoadMoreRow
            onClick={onLoadMoreClick}
            loadingNextPage={loadMoreProps?.loadingNextPage}
            pageSize={loadMoreProps?.pageSize}
            page={loadMoreProps?.page}
            total={loadMoreProps?.total}
          />
        )}
      </div>
    </>
  )
}

DataTable.propTypes = {
  /**
   * An array of objects containing the data to be displayed within the DataTable component
   */
  data: PropTypes.array,
  /**
   * Array of column definitions in the format used by the react-table component (https://react-table.tanstack.com/docs/api/useTable#column-options)
   */
  columns: PropTypes.array.isRequired,
  /**
   * Custom class name for the root HTML table element
   */
  className: PropTypes.string,
  /**
   * Custom empty list message
   */
  emptyMessage: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  /**
   * Renders the data table loading state. If a number is passed, renders that number of loading rows.
   */
  loading: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
  /**
   * A flag to decorate the table with expandable row props.
   * Unless given a "ExpandedRowComponent" prop, uses the [default `getSubRows`
   * functionality in react-table](https://react-table-v7.tanstack.com/docs/api/useTable#table-options),
   * which is to look for data in the subRows attribute of a row data object.
   * The subRows will be rendered in the same format as the original table.
   */
  expandableRows: PropTypes.bool,
  /**
   * A component to render the content of an expanded row.
   * It's rendered with the following props:
   *
   *   - `data`: memoized data that is feeding the table
   *   - `row`: react-table row object
   *   - `rowProps`: react-table generated row props, as used to render a row
   *   - `tableProps`: react-table generated table props, as used to render the table
   *
   * It's expected to render a row and its content, so assume the you need to
   * wrap it in a table row element (e.g: alma-mater's `Table.Row` or `tr`).
   */
  ExpandedRowComponent: PropTypes.elementType,
  /**
   * A callback function to be called when the user clicks on a sortable column's header. `(column: ReactTableColumn, updateTableSortState: function): void`
   */
  onChangeSort: PropTypes.func,
  /**
   * Custom options to be passed on to react-table props (https://react-table-v7.tanstack.com/docs/api/useTable#table-options)
   */
  tableProps: PropTypes.object,
  /**
   * Error object that must contain a message property to be displyed
   */
  error: PropTypes.shape({
    message: PropTypes.oneOfType([PropTypes.string, PropTypes.element])
  }),
  /**
   * Use a fixed table-layout style (allow setting column widths or column's "flexibleWidth: true" prop)
   */
  fixedLayout: PropTypes.bool,
  /**
   * Filters to be passed as `filters` prop to alma mater's filter component
   */
  filters: PropTypes.array,
  /**
   * Callback function to be passed as the `onFilterChange` prop to alma mater's filter component
   */
  onFilterChange: PropTypes.func,
  /**
   * Filter object according to alma mater's Filter component specification
   */
  selectedFilter: PropTypes.object,
  /**
   * Other props supported by alma mater's Filter component (e.g: `onOptionsLoaded`)
   */
  filterProps: PropTypes.object
}

export default DataTable
