import React, { useCallback, useEffect, useRef } from 'react'
import { useQuery } from 'react-query'

import { utils } from '@fullfabric/public-api'

import FullScreenLoading from 'shared/components/FullScreenLoading'
import { useAppData } from 'shared/contexts/AppData'
import usePusher from 'shared/hooks/usePusher'

import useSettingByName from 'apps/ContentPages/hooks/use-setting-by-name'
import { useMergeTags } from 'apps/EmailTemplates/hooks/useMergeTags'
import { onDesignSaveDebounced } from '../../utils/onDesignSave'
import { uploadImage } from '../../utils/uploadImage'

import styles from './styles.module.scss'

export default function UnlayerEditor({ emailTemplate, onSave, onChange }) {
  const { config, _handle: handle, _user: user } = useAppData()
  const mergeTags = useMergeTags()
  const emailEditorRef = useRef(null)
  const [loadingEditor, setLoadingEditor] = React.useState(true)

  const assetsPath =
    process.env.NODE_ENV === 'development'
      ? `http://${handle}.localhost:3001/assets`
      : config.assets.prefix

  const colors = useSettingByName(
    'modules.core.submodules.email_templates.themes.colors'
  )

  usePusher({
    channelName: 'tus-uploads-attachments',
    binds: ['local-upload:success', 'remote-upload:success'],
    opts: { tenant: true },
    onMessage: ({ bind, data }) => {
      notifyUnlayer(
        bind === 'local-upload:success' ? 'uploading' : 'upload',
        data
      )
    }
  })

  const { data, isLoading } = useQuery(['emailTemplateInfo'], async () => {
    const response = await fetch(`/api/email_templates/editor_info`, {
      credentials: 'same-origin'
    })
    return utils.checkResponse(response)
  })

  const onDesignUpdate = useCallback(() => {
    onChange()

    if (emailEditorRef.current) {
      onDesignSaveDebounced(emailEditorRef.current, onSave)
    }
  }, [onChange, onSave])

  const notifyUnlayer = (type, data) => {
    emailEditorRef.current.frame.iframe.contentWindow.postMessage(
      { event: `fullfabric:${type}`, data },
      '*'
    )
  }

  const onEditorReady = useCallback(() => {
    emailEditorRef.current.addEventListener('design:loaded', onDesignUpdate)
    emailEditorRef.current.registerCallback('image', async (file, done) => {
      done({ progress: 10 })
      const { url } = await uploadImage(emailTemplate.id, file)

      done({ progress: 100, url })
    })

    emailEditorRef.current.loadDesign(emailTemplate.design)

    setLoadingEditor(false)

    // We disable exhaustive deps because we don't want to re-render the whole
    // editor every time the attachments are changed, i.e., every time we're
    // back in window scope and react-query refetches the template.
    //
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [emailTemplate.id, handle, user.token, onDesignUpdate])

  // Keep our custom Attachments tab up to date with config for uploads:
  useEffect(() => {
    if (loadingEditor) return

    notifyUnlayer('data', {
      host: window.location.origin,
      attachments: emailTemplate.attachments,
      templateId: emailTemplate.id,
      channelName: `${handle}-tus-uploads-attachments`,
      userToken: user.token
    })
  }, [emailTemplate, handle, loadingEditor, user.token])

  // Create the Unlayer editor once all the data we need is ready:
  const [unlayerOptions, unlayerOptionsAsString] = buildUnlayerOptions({
    assetsPath,
    data,
    mergeTags,
    colors
  })

  useEffect(() => {
    if (isLoading) return

    emailEditorRef.current = window.unlayer.createEditor(unlayerOptions)

    onEditorReady()

    return () => emailEditorRef.current?.destroy()

    // We're listening for options in `unlayerOptions` already by their
    // stringified version.
    //
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading, unlayerOptionsAsString, onEditorReady])

  useEffect(() => {
    if (!emailEditorRef.current) return

    emailEditorRef.current.addEventListener('design:updated', onDesignUpdate)

    return () => {
      emailEditorRef.current.removeEventListener(
        'design:loaded',
        onDesignUpdate
      )

      emailEditorRef.current.removeEventListener(
        'design:updated',
        onDesignUpdate
      )
    }
  }, [isLoading, onDesignUpdate])

  return (
    <div className={styles.editorContainer}>
      {(loadingEditor || isLoading) && (
        <div className={styles.loadingContainer}>
          <FullScreenLoading />
        </div>
      )}

      <div
        aria-label='Unlayer email editor'
        role='main'
        style={{ display: loadingEditor ? 'none' : 'block', height: '100%' }}
        id='unlayer-editor'
      />
    </div>
  )
}

function buildUnlayerOptions({ assetsPath, data, mergeTags, colors }) {
  const features = {}

  if (colors.length > 0) {
    features.colorPicker = { presets: colors.map((color) => `#${color}`) }
  }

  const options = {
    id: 'unlayer-editor',
    displayMode: 'email',
    designMode: 'live',
    mergeTags,
    features,
    projectId: data?.project_id,
    user: data?.user,
    customJS: [
      `${assetsPath}/vendor/app/v5/email.js`,
      `${assetsPath}/vendor/app/v5/tus.js`
    ]
  }

  return [options, JSON.stringify(options)]
}
