import React from 'react'

import moment from 'moment'

import { Table } from '@fullfabric/alma-mater'

import cellStyles from 'shared/components/cells/Cells.module.scss'

export default function DateTimeCell({ value }) {
  if (!value) {
    return (
      <Table.Data className={cellStyles.customCell} tag='div'>
        -
      </Table.Data>
    )
  }

  const date = moment(value)
  const formattedTime = date.format('MMM Do YYYY, h:mm:ss a')

  return (
    <Table.Data
      tag='div'
      className={cellStyles.customCell}
      complementaryInfo={date.fromNow()}
    >
      {formattedTime}
    </Table.Data>
  )
}
