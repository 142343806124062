import React, { useCallback } from 'react'
import { Trans } from 'react-i18next'
import { useMutation } from 'react-query'

import {
  Button,
  Dropdown,
  PointerDownIcon,
  Table
} from '@fullfabric/alma-mater'

import activateStaff from 'shared/api/staff/activateStaff'
import getStaff from 'shared/api/staff/getStaff'
import sendInstructionsToStaff from 'shared/api/staff/sendInstructionsToStaff'
import suspendStaff from 'shared/api/staff/suspendStaff'
import cellStyles from 'shared/components/cells/Cells.module.scss'
import { usePagedQueriesUpdater } from 'shared/hooks/usePagedQueriesUpdater'

import cn from 'classnames'

export default function StatusCell({ data, row, value }) {
  const profileId = data[row.index].id
  const isActive = value.some(
    ({ state, substate }) => state === 'user' && substate === 'active'
  )

  const { mutate: mutateStatus, isLoading } = useStatusMutation(profileId)

  const handleActivate = useCallback(
    () => mutateStatus(activateStaff),
    [mutateStatus]
  )
  const handleSuspend = useCallback(
    () => mutateStatus(suspendStaff),
    [mutateStatus]
  )
  const handleResendInvite = useCallback(
    () => mutateStatus(sendInstructionsToStaff),
    [mutateStatus]
  )

  const options = []

  if (isActive) {
    options.push(
      <Dropdown.Menu.Option
        disabled={isLoading}
        size='small'
        title='Suspend this user'
        onClick={handleSuspend}
      >
        <Trans>Suspend</Trans>
      </Dropdown.Menu.Option>
    )
  } else {
    options.push(
      <Dropdown.Menu.Option
        disabled={isLoading}
        size='small'
        title='Activate this user'
        onClick={handleActivate}
      >
        <Trans>Activate</Trans>
      </Dropdown.Menu.Option>,
      <Dropdown.Menu.Option
        disabled={isLoading}
        size='small'
        title='Re-send invitation'
        onClick={handleResendInvite}
      >
        <Trans>Re-send invitation</Trans>
      </Dropdown.Menu.Option>
    )
  }

  return (
    <Table.Data tag='div' className={cellStyles.customCell}>
      <Dropdown>
        <Dropdown.Toggle>
          <Button
            className={cn(cellStyles.iconButton, cellStyles.tableButton)}
            size='small'
            negative={!isActive}
          >
            {isActive ? 'Active' : 'Suspended'}
            <PointerDownIcon color={isActive ? null : 'text-alert'} />
          </Button>
        </Dropdown.Toggle>

        <Dropdown.Menu>{options}</Dropdown.Menu>
      </Dropdown>
    </Table.Data>
  )
}

function useStatusMutation(profileId) {
  const updatePages = usePagedQueriesUpdater([
    'tableDataFetch',
    undefined,
    getStaff
  ])

  return useMutation((actionFn) => actionFn(profileId), {
    onSuccess: (staff) => {
      const newUserRole = staff.roles.find(({ state }) => state === 'user')

      updatePages((page) => {
        page.data.forEach((profile) => {
          if (profile.id === profileId) {
            profile.roles = profile.roles.map((role) => {
              if (role.id === newUserRole.id) return newUserRole
              return role
            })
          }
        })
      })
    }
  })
}
