import React from 'react'
import { Trans } from 'react-i18next'
import { useMutation } from 'react-query'

import { uniq } from 'lodash'

import {
  Button,
  Dropdown,
  Loading,
  PointerDownIcon,
  Table
} from '@fullfabric/alma-mater'

import getStaff from 'shared/api/staff/getStaff'
import updateStaff from 'shared/api/updateStaff'
import cellStyles from 'shared/components/cells/Cells.module.scss'
import { usePagedQueriesUpdater } from 'shared/hooks/usePagedQueriesUpdater'

import cn from 'classnames'

export default function TeamsCell({
  data,
  teams,
  isLoading,
  row,
  value: teamIds
}) {
  const profileTeamNames = teamIds?.length
    ? teams
        .filter(({ id }) => teamIds.includes(id))
        .map(({ name }) => name)
        .join(', ')
    : '[No team]'

  const profileId = data[row.index].id
  const { mutate: updateStaffMutation, isLoading: isLoadingUpdate } =
    useStaffMutation(profileId)

  const toggleTeam = (checkboxId, selected) => {
    const [, teamId] = /select-team-(\w+)/.exec(checkboxId)

    const newTeamIds = selected
      ? uniq([...teamIds, teamId])
      : teamIds.filter((id) => id !== teamId)

    updateStaffMutation(newTeamIds)
  }

  return (
    <Table.Data
      tag='div'
      className={cellStyles.customCell}
      title={<Trans>Teams</Trans>}
    >
      {isLoading ? (
        <Loading />
      ) : (
        <Dropdown>
          <Dropdown.Toggle>
            <Button
              className={cn(cellStyles.iconButton, cellStyles.tableButton)}
              size='small'
            >
              {profileTeamNames}
              <PointerDownIcon />
            </Button>
          </Dropdown.Toggle>

          <Dropdown.Menu>
            {teams.map(({ id: teamId, name }) => (
              <Dropdown.Menu.CheckableOption
                id={`select-team-${teamId}`}
                key={teamId}
                checked={teamIds?.includes(teamId)}
                disabled={isLoadingUpdate}
                onChange={toggleTeam}
              >
                {name}
              </Dropdown.Menu.CheckableOption>
            ))}
          </Dropdown.Menu>
        </Dropdown>
      )}
    </Table.Data>
  )
}

function useStaffMutation(profileId) {
  const replaceStaff = useCachedStaffReplacer()

  return useMutation(
    (teamIds) => updateStaff(profileId, { teams_ids: teamIds }),
    { onSuccess: replaceStaff }
  )
}

function useCachedStaffReplacer() {
  const updatePages = usePagedQueriesUpdater([
    'tableDataFetch',
    undefined,
    getStaff
  ])

  return (staff) =>
    updatePages((page) => {
      page.data.forEach((profile) => {
        if (profile.id === staff.id) profile.teams_ids = staff.teams_ids
      })
    })
}
